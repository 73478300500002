import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { CartService } from "./panel/shared/cart.service";
import { DisplaySettingsService } from "./panel/shared/display-settings.service";
import { StripeService } from "ngx-stripe";
import { environment } from "../environments/environment";
import { FacebookService, InitParams } from "ngx-facebook";
import { FirebaseService } from './panel/shared/firebase.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Ticketing - PPS';
  iframeContent = false;

  constructor(private cartService: CartService,
              private stripeService: StripeService,
              private fb: FacebookService,
              private elRef: ElementRef,
              private displaySettingsService: DisplaySettingsService,
              private translate: TranslateService,
              private firebaseService: FirebaseService) {
    translate.setDefaultLang('en');
    translate.addLangs(['en', 'es']);
    translate.use(localStorage.getItem('translate') || 'en');
    if (window.location.href.indexOf('/iframe') === -1) {
      const initParams: InitParams = {
        appId: environment.fbID,
        xfbml: true,
        cookie: true,
        version: 'v7.0'
      };
      fb.init(initParams)
      this.displaySettingsService.getMyConfigs().subscribe(data => {
        if (!data.theme.main_color) data.theme = {main_color: '#6caddf', secondary_color: '#df2c2c'};
        this.firebaseService.logEvent('Framework_Initialized');
        document.documentElement.style.setProperty('--first-color', data.theme.main_color);
        document.documentElement.style.setProperty('--second-color', data.theme.secondary_color);
        this.displaySettingsService.config.next(data);
      }, error => {
      });
      this.stripeService.setKey(environment.stripeKey);
      this.cartService.getCart().subscribe(
        data => {
          console.log("data", data);
          this.cartService.cart.next(data);
        }, error => {}
      )
    } else {
      this.iframeContent = true;
    }
  }

  ngOnInit(): void {
    this.firebaseService.logEvent('Framework_Opened');
  }

  ngOnDestroy() {
    this.firebaseService.logEvent('Framework_Closed');
  }

  ngAfterViewInit(): void {
    $(document).click(function (event) {
      var click = $(event.target);
      var _open = $(".navbar-collapse").hasClass("show");
      if (_open === true && !click.hasClass("navbar-toggler")) {
        $(".navbar-toggler").click();
      }
    });
  }
}
