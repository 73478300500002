import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from './base-model.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class DisplaySettingsService extends BaseModelService {
  categories: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  config: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private injector: Injector) {
    super(injector, '');
  }

  getCategories(data?) {
    return this.http.get(environment.apiUrl + `application/v1/display_settings/categories/`, data);
  }

  getMyConfigs(data?) {
    return this.http.get(environment.apiUrl + `application/v1/display_settings/get_my_configs/`, data);
  }

  getSections(params?) {
    return this.http.get(environment.apiUrl + `application/v1/display_settings/sections/`, {params});
  }

  getSectionDetails(id, params?) {
    return this.http.get(environment.apiUrl + `application/v1/display_settings/sections/${id}/`, {params});
  }

  variantSelect(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/select/`, data);
  }

  variantSet(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/set/`, data);
  }

  variantUnselect(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/unselect/`, data);
  }

}
