import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from './base-model.service';
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class EnumsService extends BaseModelService {
  enums: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private injector: Injector) {
    super(injector, 'products/v1/products/');
  }

  getEnums(params?) {
    return this.http.get(environment.apiUrl + 'any/common/v1/enums', {params});
  }

  //
  // getAllVariants(id) {
  //   return this.http.get(environment.wordPressApiUrl + `wc/v3/products/${id}/variations`);
  // }
}
