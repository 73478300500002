<div class="root-container">
  <!--    <div class="container">-->
  <!--      <form [formGroup]="loginForm" (ngSubmit)="onLogin()">-->
  <!--        <input type="text"-->
  <!--               formControlName="username"-->
  <!--               class="custom-input"-->
  <!--               placeholder="Your Email">-->
  <!--        <input type="password"-->
  <!--               formControlName="password"-->
  <!--               class="custom-input"-->
  <!--               placeholder="Password">-->

  <!--        <button type="submit">-->
  <!--          LOG IN-->
  <!--        </button>-->
  <!--      </form>-->


  <!--    <div class="row tiles-big mt-4 mb-4">-->
  <!--      <div class="col-sm-6 col-lg-4 " *ngFor="let event of events" style="margin: 10px 0;">-->
  <!--        <div class="tile" [ngStyle]="{'background-image':'url('+ event?.photo?.url+')'}">-->
  <!--          &lt;!&ndash;          <div>&ndash;&gt;-->
  <!--          <div class="name">{{event.name}}</div>-->
  <!--          <div style="width:100%;display: flex;flex-direction: column;">-->
  <!--            <div class="tile-buttons">-->
  <!--              <div>-->
  <!--                <div class="label">Price</div>-->
  <!--                <div class="amount">{{50 | currency}}</div>-->
  <!--              </div>-->
  <!--              <div>-->
  <!--                <div class="label">No of ticket</div>-->
  <!--                <div class="inputs">-->
  <!--                  &lt;!&ndash;                  <div>&ndash;&gt;-->
  <!--                  <div class="change-amount">-</div>-->
  <!--                  <input type="text" value="1">-->
  <!--                  <div class="change-amount">+</div>-->
  <!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div class="d-flex justify-content-center">-->
  <!--              <button>ADD TO CART</button>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div>-->

  <!--      <button type="button"-->
  <!--              (click)="getUser()">-->
  <!--        get user-->
  <!--      </button>-->

  <!--      <button type="button"-->
  <!--              (click)="logout()">-->
  <!--        logout-->
  <!--      </button>-->

  <!--      &lt;!&ndash;      <div class="alert alert-primary" role="alert">&ndash;&gt;-->
  <!--      &lt;!&ndash;        A simple primary alert—check it out!&ndash;&gt;-->
  <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
  <!--    </div>-->
  <!--  </div>-->
  <div *ngIf="!iframeContent">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
  <div *ngIf="iframeContent">
    <router-outlet></router-outlet>
  </div>
  <app-loader></app-loader>
</div>
