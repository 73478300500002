export const environment = {
  production: false,
  showFirebaseLogs: true,
  secretKey: '_k_bdUgx9ifWn4w33ZCAzL4Ae-Hl8cyc2R-BcCaOOO8',
  secretKeyBase: '_k_bdUgx9ifWn4w33ZCAzL4Ae-Hl8cyc2R-BcCaOOO8',
  stripeKey: 'pk_test_GRGxMdcpNkmtMFEvKWiUeDkn00nDk6lEeO',
  TBApiUrl: 'https://tripbucket.qa-aws.i2asolutions.com/api/v2/pps/',
  apiUrl: 'https://pps-backend-stg.i2asolutions.com/api/',
  fbID: '608319389774069',
  externalAppId: 'Iowa',
  externalAppIdTB: '1',
  deviceType: 'Web',
  firebase: {
    apiKey: "AIzaSyB5NNh_9-oIpFbSse1BPrIpFSA-Kui0Sh0",
    authDomain: "i2asolutions.firebaseapp.com",
    databaseURL: "https://i2asolutions.firebaseio.com",
    projectId: "i2asolutions",
    storageBucket: "i2asolutions.appspot.com",
    messagingSenderId: "137408447052",
    appId: "1:137408447052:web:42fda534f1ba3db9daaae9",
    measurementId: "G-1DRGS13B6B"
  },
  wynwood: {
    secretKey: 'gIdJVyVx9YzQyV3Mt6fFE1cdpTiWEsVzf6L6w_bOjbg',
    secretKeyBase: '',
    stripeKey: 'pk_test_GRGxMdcpNkmtMFEvKWiUeDkn00nDk6lEeO',
    externalAppId: 'WYNWOOD',
    externalAppIdTB: '7',
    deviceType: 'Iframe_Web',
    authClientKey: '7sec85DMkgHTkYwd978uSscEC72N85LQrQhtFLc97r7wsLKUdDXj7Ac7d66Xv4sQ',
    authApiLoginID: '9jmMs82j5GHy',
    liveAuthNet: false,
    firebase: {
      apiKey: "AIzaSyDTdOKwy1y_QaHZkGY-wNWslTFCcoHi_pk",
      authDomain: "wynwood-walls.firebaseapp.com",
      databaseURL: "https://wynwood-walls.firebaseio.com",
      projectId: "wynwood-walls",
      storageBucket: "wynwood-walls.appspot.com",
      messagingSenderId: "1065213642709",
      appId: "1:1065213642709:web:12079c8fa8936dbdecdf5c",
      measurementId: "G-V784D7NM7T"
    }
  },
  kiosk: {
    secretKey: 'iDb7qCBLJKOGgyZUuvfS1e99j9bYQKF-4P17wHPD4YI',
    secretKeyBase: 'gIdJVyVx9YzQyV3Mt6fFE1cdpTiWEsVzf6L6w_bOjbg',
    stripeKey: 'pk_test_GRGxMdcpNkmtMFEvKWiUeDkn00nDk6lEeO',
    externalAppId: 'WYNWOOD',
    externalAppIdTB: '7',
    deviceType: 'Web_Kiosk',
  },
  dejavoo: {
    spinposUrl: 'https://spinpos.net/spin/'
  }
};
