import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { CartService } from "../../shared/cart.service";
import { CountdownComponent } from "ngx-countdown";
import * as moment from 'moment';
import { ConfigController } from "../../shared/config-controller";
import { FirebaseService } from "../../shared/firebase.service";
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends ConfigController implements OnInit, OnDestroy {
  @ViewChild('cd', {static: false}) private countdown: CountdownComponent;
  tokenCheckedSubscription: Subscription;
  tokenChecked;
  cart;
  countdownConfig = {
    format: 'mm:ss',
    notify: [120]
  };
  runCountdown = false;

  constructor(private cartService: CartService,
              private injector: Injector,
              private toastr: ToastrService,
              private firebaseService: FirebaseService) {
    super(injector);
    this.cartService.cart.subscribe(data => {
      // Avoid initial call
      if (data && this.cart && (data.selected_products_count !== this.cart.selected_products_count)) {
        this.firebaseService.logEvent('Cart_Quantity_Update', {
          quantity: data.selected_products_count || 0
        });
      }
      this.cart = data;
      if (!data) return;
      this.countdownConfig['leftTime'] = moment(data['expiration_date']).unix() - moment().unix();
      if (this.runCountdown) {
        this.runCountdown = false;
        this.countdownConfig['leftTime'] = moment(data['expiration_date']).unix() - moment().unix();
      }
      setTimeout(() => {
        this.runCountdown = true;
      })
    })
    this.tokenCheckedSubscription = this.authService.tokenChecked.subscribe(data => {
      this.tokenChecked = data;
    });
    // this.countdown.begin();
  }

  ngOnInit() {
  }

  onLogout() {
    this.authService.logout();
  }

  eventCountdown(event) {
    if (event.action === 'notify') {
      this.toastr.info("The tickets in your cart will be removed in 2 minutes if they are not purchased.");
    }
    if (event.action === 'done') {
      setTimeout(() => {
        this.runCountdown = false;
        this.cartService.cart.next(null);
      });
    }
  }

  sendLog() {
    this.firebaseService.logEvent('Cart_Icon_Tapped');
  }

  collapseMenu() {
    $('.navbar-collapse').collapse('hide');
  }

  ngOnDestroy() {
    this.tokenCheckedSubscription && this.tokenCheckedSubscription.unsubscribe();
  }
}
