import { Injectable } from '@angular/core';
import 'firebase/analytics';
import * as firebase from "firebase/app";
import "firebase/analytics";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class FirebaseService {
  constructor() {
    // console.log("firebase", firebase);
    firebase.initializeApp(environment.wynwood.firebase).analytics();
  }

  logEvent(eventName, eventParams?, options?) {
    // setTimeout(() => {
    firebase.analytics().logEvent(eventName, eventParams, options);
    // }, 1000);
  }
}
