import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { BaseModelService } from '../../panel/shared/base-model.service';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class KioskService extends BaseModelService {
  isKioskContent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private injector: Injector) {
    super(injector, '');
  }

  getTicketPDF(identifier) {
    return this.http.get(environment.apiUrl + `device/sales/v1/print_order/${identifier}/`, {responseType: 'blob'});
  }

  printTicket(data?) {
    let headers = new HttpHeaders();
    // headers = headers.set('Content-Type', 'multipart/form-data');
    return this.http.post('https://127.0.0.1:8080/print/', data, { headers: headers, withCredentials: false, responseType: 'text' });
  }

  getMacAddress() {
    return this.http.get('https://127.0.0.1:8080/mac/', {withCredentials: false, responseType: 'text'});
    // return this.http.get('https://aa1dfc832eab.ngrok.io/mac', { withCredentials: false, responseType: 'text' });
  }
}
