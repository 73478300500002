import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../../panel/shared/loader.service';
import { environment } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from "./auth.service";
import { ToastrService } from "ngx-toastr";
import { KioskService } from "../../iframe/shared/kiosk.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private kioskService: KioskService,
    private toastr: ToastrService
  ) {
  }

  requestCount = 0;

  clearStaffAppLocalStorage() {
    localStorage.removeItem('PPS-USE-INTERNAL-AUTH');
    localStorage.removeItem('auth');
    localStorage.removeItem('uuid');
    localStorage.removeItem('macAddress');
    localStorage.removeItem('staff');
    localStorage.removeItem('cert');
    localStorage.removeItem('authKey');
    localStorage.removeItem('registerId');
  }

  addToken(request: HttpRequest<any>): HttpRequest<any> {
    const req = request.clone({});
    let headers = req.headers;
    headers = headers.set('Accept-Language', localStorage.getItem('language') || 'en-en');
    if (req.url.indexOf(environment.apiUrl) !== -1) {
      let auth = JSON.parse(localStorage.getItem('auth'));
      let ppsUseInternalAuth = JSON.parse(localStorage.getItem('PPS-USE-INTERNAL-AUTH'));
      if (!headers.get('PPS-USER-AUTHORIZATION') && auth) {
        if (auth.hasOwnProperty('api_key') && auth['api_key']) {
          headers = headers.set('PPS-USER-AUTHORIZATION', `ApiKey ${auth['username']}:${auth['api_key']}`);
        }
        if (+ppsUseInternalAuth === 1) {
          headers = headers.set('PPS-USE-INTERNAL-AUTH', `1`);
          headers = headers.set('PPS-USER-AUTHORIZATION', `${auth['qr_code']}`);
        }
      }
      if (environment.secretKey) {
        headers = headers.set('PPS-CLIENT-SECRET', +ppsUseInternalAuth === 1 ? environment.secretKeyBase : environment.secretKey);
      }
      headers = headers.set('PPS-DEVICE-TYPE', environment.deviceType);
      let uuid = localStorage.getItem('uuid') || null;
      if (!localStorage.getItem('uuid')) {
        uuid = uuidv4();
        localStorage.setItem('uuid', uuid);
      }
      const macAddress = localStorage.getItem('macAddress');
      headers = headers.set('PPS-DEVICE-ID', macAddress || uuid);
      if (localStorage.getItem('PPS-PLACE-ID')) {
        headers = headers.set('PPS-PLACE-ID', localStorage.getItem('PPS-PLACE-ID'));
      }
      let staff = JSON.parse(localStorage.getItem('staff'));
      if (staff && staff.token && (this.router.url.indexOf('iframe/kiosk/staff-login') !== -1 || this.router.url.indexOf('iframe/kiosk/maintenance') !== -1)) {
        headers = headers.set('PPS-USER-AUTHORIZATION', staff.token);
        // headers = headers.set('PPS-PLACE-ID', '2');
      }
      let cert = JSON.parse(localStorage.getItem('cert'));
      if (cert && cert.certs && this.router.url.indexOf('iframe/kiosk') !== -1) {
        headers = headers.set('PPS-DEVICE-AUTH-CERT', cert.certs);
      }
    }

    if (req.url.indexOf('api/v2/pps/logout/') !== -1 || req.url.indexOf('api/v2/pps/user/me/') !== -1 || req.url.indexOf('user/change_password/') !== -1 || req.url.indexOf('api/v2/pps/signup/') !== -1 || req.url.indexOf('api/v2/pps/forgot_password/') !== -1) {
      let auth = JSON.parse(localStorage.getItem('auth'));
      if (!headers.get('authorization') && auth && auth.hasOwnProperty('api_key') && auth['api_key']) {
        headers = headers.set('authorization', `ApiKey ${auth['username']}:${auth['api_key']}`);
      }
      headers = headers.set('EXTERNAL-APP-ID', environment.externalAppIdTB);
    }

    if (req.url.indexOf('api/v2/pps/login') !== -1) {
      headers = headers.set('EXTERNAL-APP-ID', environment.externalAppIdTB);
    }

    // Here, we have to send '3' instead of Web - TB Backend...
    if (req.url.indexOf('api/v2/pps/apple_login/') !== -1) {
      headers = headers.set('DEVICE-TYPE', '3');
    }
    return req.clone({headers});
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    ++this.requestCount;
    this.loaderService.show();

    return next.handle(this.addToken(req)).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        --this.requestCount;
        if (this.requestCount <= 0) {
          this.loaderService.hide();
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.clearStaffAppLocalStorage();
          // this.router.navigate(['/account/login']);
          // this.router.navigate(['/dashboard']);
        }
        if (err.status === 403) {
          if (window.location.href.indexOf('/iframe/kiosk/') !== -1) {
            if (window.location.href.indexOf('/iframe/kiosk/staff-login') === -1) {
              if (window.location.href.indexOf('/iframe/kiosk/login') !== -1) {
              } else {
                this.clearStaffAppLocalStorage();
                this.router.navigate(['/iframe/kiosk/staff-login']);
                window.location.reload();
              }
            } else {
              this.clearStaffAppLocalStorage();
              this.kioskService.getMacAddress().subscribe(data => {
                localStorage.setItem('macAddress', data);
              }, error => {
                this.toastr.error('Kiosk App Service Error');
              });
            }
          }
          localStorage.removeItem('auth');
          if (err.error.error_code === 100410) {
            localStorage.removeItem('PPS-PLACE-ID');
            window.location.reload();
          }
        }
      }
      --this.requestCount;
      if (this.requestCount <= 0) {
        this.loaderService.hide();
      }
    }));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // console.log('ERROR 401');
  }
}
