import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../shared/loader.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({opacity: 1})),
      state('hidden', style({opacity: 0})),
      transition('shown => hidden', animate('200ms')),
      transition('hidden => shown', animate('200ms')),
    ])
  ],
})
export class LoaderComponent implements OnInit, OnDestroy {

  show = false;
  private subscription: Subscription;

  constructor(
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    this.show = false;
    this.subscription = this.loaderService.loaderState
      .subscribe((state: boolean) => {
        this.show = state['show'];
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
