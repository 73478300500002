import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from './base-model.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class CartService extends BaseModelService {
  cart: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private injector: Injector) {
    super(injector, '');
  }

  getCart(data?) {
    return this.http.get(environment.apiUrl + `ordering/v1/cart_service/manage_open_cart/current_cart/`, data);
  }

  variantSelect(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/select/`, data);
  }

  variantSet(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/set/`, data);
  }

  variantEdit(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/edit_selected_addons/`, data);
  }

  editVariant(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/edit_selected_product_variant/`, data);
  }

  variantEditTimeSlot(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/edit_selected_time_slots/`, data);
  }

  variantUnselect(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/unselect/`, data);
  }

  getTimeSlotsAvailability(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/get_time_slots_availability/`, data);
  }

  getTimeSlotsAvailabilityForProduct(id, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/${id}/get_time_slots_availability_for_product/`, data);
  }

  addDiscountCode(identifier, data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/manage_open_cart/${identifier}/add_discount_code/`, data);
  }

  removeDiscountCode(identifier, data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/manage_open_cart/${identifier}/remove_discount_code/`, data);
  }

  submitFreeOrder(identifier, data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/cart/${identifier}/submit_free_order/`, data);
  }

  submitUsingRegisteredCC(identifier, data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/cart/${identifier}/submit_using_registered_cc/`, data);
  }

  submitUsingGeneric(identifier, data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/cart/${identifier}/generic_submit/`, data);
  }

  submitUsingToken(identifier, data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/cart/${identifier}/submit_using_token/`, data);
  }

  setDonation(identifier, data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/manage_open_cart/${identifier}/set_donation/`, data);
  }

  groupSet(data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/product_variants/group_set/`, data);
  }

  getUrlCart(identifier, data?) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/cart/${identifier}/get_cart/`, data);
  }

  // Get reference_token
  prepareExternalPaymentToken(id, data) {
    return this.http.post(environment.apiUrl + `ordering/v1/cart_service/cart/${id}/prepare_external_payment_attempt/`, data);
  }
}
