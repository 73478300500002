import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ConfigController } from "../../shared/config-controller";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends ConfigController implements OnInit, OnDestroy {

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
