import { Injector } from '@angular/core';
import { DisplaySettingsService } from "./display-settings.service";
import { AuthService } from "../../authentication/shared/auth.service";

export class ConfigController {
  public displaySettingsService;
  public authService;
  config;
  user;

  constructor(private injectorObj: Injector) {
    this.displaySettingsService = this.injectorObj.get(DisplaySettingsService);
    this.authService = this.injectorObj.get(AuthService);

    this.displaySettingsService.config.subscribe(data => {
      if(!data) return;
      this.config = data;
    })

    this.authService.user.subscribe(data => {
      this.user = data;
    });
  }
}
