import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class BaseModelService {
  url: string;
  http: any;
  router: any;

  constructor(private injectorObj: Injector, url: string) {
    this.http = this.injectorObj.get(HttpClient);
    // this.router = this.injectorObj.get(Router);
    this.url = url;
  }

  /**
   * Lists
   */
  getList(params?) {
    return this.http.get(environment.apiUrl + this.url + '?', {params});
  };

  postList(data) {
    return this.http.post(environment.apiUrl + this.url, data);
  };

  /**
   * Details
   */
  getDetail(id) {
    return this.http.get(environment.apiUrl + this.url + id + '/');
  };

  postDetail(id, data) {
    return this.http.post(environment.apiUrl + this.url + id + '/', data);
  };

  patchDetail(id, data) {
    return this.http.patch(environment.apiUrl + this.url + id + '/', data);
  };

  putDetail(id, data) {
    return this.http.put(environment.apiUrl + this.url + id + '/', data);
  };

  deleteDetail(id) {
    return this.http.delete(environment.apiUrl + this.url + id + '/');
  };
}
