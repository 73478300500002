import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { EnumsService } from '../../panel/shared/enums.service';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../panel/shared/cart.service';
import { FacebookService } from 'ngx-facebook';
import { FirebaseService } from '../../panel/shared/firebase.service';
import * as moment from "moment";
import { TranslateService } from '@ngx-translate/core';

declare var FB: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tokenChecked: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  logoutDate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  logoutInterval = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private enumsService: EnumsService,
    private toastr: ToastrService,
    private cartService: CartService,
    private fb: FacebookService,
    private firebaseService: FirebaseService,
    private translateService: TranslateService
  ) {
    if (!this.tokenChecked.value) {
      const token = JSON.parse(localStorage.getItem('auth'));
      if (token && ((token.hasOwnProperty('api_key') && token.api_key) || (token.hasOwnProperty('qr_code') && token.qr_code))) {
        let promises = [
          this.me().toPromise(),
          this.enumsService.getEnums().toPromise()
        ];
        if (token.hasOwnProperty('qr_code') && token.qr_code) {
          promises.push(this.getInternalAuthToken().toPromise())
        }
        Promise.all(promises).then(([me, enums, internalAuthToken]) => {
          this.enumsService.enums.next(enums);
          this.user.next(me);
          this.tokenChecked.next(true);
          if (internalAuthToken) {
            localStorage.setItem('auth', JSON.stringify({
              qr_code: internalAuthToken['token']
            }));
            this.logoutDate.next(internalAuthToken['expiry_at']);
          }
          // resolve(true);
        }).catch(error => {
          localStorage.removeItem('auth');
          localStorage.removeItem('uuid');
          this.user.next(null);
          this.cartService.cart.next(null);
          this.tokenChecked.next(true);
        });
      } else {
        localStorage.removeItem('auth');
        this.user.next(null);
        this.tokenChecked.next(true);
      }
    }
    this.logoutDate.subscribe(x => {
      if (x) {
        this.logoutInterval = setInterval(() => {
          if (moment().isSameOrAfter(moment(x))) {
           localStorage.removeItem('auth');
           localStorage.removeItem('PPS-USE-INTERNAL-AUTH');
            clearInterval(this.logoutInterval);
            window.location.reload();
          }
        }, 1000);
      } else {
        clearInterval(this.logoutInterval);
      }
    });
  }

  login(data) {
    return this.http.post(environment.TBApiUrl + 'login/', data);
  }

  staffLogin(data) {
    let headers = new HttpHeaders();
    headers = headers.set('PPS-LOGIN-AUTHORIZATION', 'Basic ' + btoa(data.username + ":" + data.password));
    return this.http.post(environment.apiUrl + `any/internal_users/v1/login/`, {}, {headers: headers});
  }

  generateDeviceCertificate() {
    return this.http.get(environment.apiUrl + `sale_user/common/v1/device_certs/get_or_generate_device_certs/`);
  }

  FBLogin(data) {
    return this.http.post(environment.TBApiUrl + 'facebook_login/', data);
  }

  appleLogin(data) {
    return this.http.post(environment.TBApiUrl + 'apple_login/', data);
  }

  register(data) {
    return this.http.post(environment.TBApiUrl + 'signup/', data);
  }

  logout(disableRedirect?, isWynwoodApp?) {
    // if (localStorage.getItem('token')) {
    this.firebaseService.logEvent('Logout');
    this.http.post(environment.TBApiUrl + 'logout/', {}).subscribe((data) => {
      this.logoutAction(disableRedirect);
      this.toastr.success(this.translateService.instant("toast.logoutSuccess"));
      isWynwoodApp && this.router.navigate(['/iframe/wynwood']);
    }, error => {
    });
    // }
    // this.logoutAction();
  }

  getTBUserData() {
    return this.http.get(environment.TBApiUrl + 'user/me/', {});
  }

  logoutAction(disableRedirect?) {
    localStorage.removeItem('PPS-USE-INTERNAL-AUTH');
    localStorage.removeItem('auth');
    localStorage.removeItem('uuid');
    this.user.next(null);
    this.cartService.cart.next(null);
    this.fb.getLoginStatus()
      .then(data => {
        if (data && data.authResponse) {
          FB.logout((response) => {
          });
        }
      })
      .catch(console.error.bind(console));
    if (disableRedirect) return;
    console.log('disableRedirect', disableRedirect);
    this.router.navigate(['/account/login']);
  }

  me() {
    return this.http.get(environment.apiUrl + 'any/common/v1/get_me/');
  }

  passwordResetSend(data) {
    return this.http.post(environment.TBApiUrl + 'forgot_password/', data);
  }

  passwordResetChange(data) {
    return this.http.post(environment.TBApiUrl + 'reset_password/', data);
  }

  passwordResetCheckToken(data) {
    return this.http.post(environment.TBApiUrl + 'web/accounts/password_reset/check_token/', data);
  }

  passwordChange(data) {
    return this.http.post(environment.TBApiUrl + 'user/change_password/', data);
  }

  getMe() {
    return this.http.get(environment.apiUrl + 'end_user/users/v1/user_data/get_me/');
  }

  getUserAddressesData() {
    return this.http.get(environment.apiUrl + 'end_user/users/v1/user_data/addresses/');
  }

  getUserBillingData() {
    return this.http.get(environment.apiUrl + 'end_user/users/v1/user_data/billings/');
  }

  addUserBillingData(data) {
    return this.http.post(environment.apiUrl + `end_user/users/v1/user_data/billings/`, data);
  }

  setUserBillingData(id, data) {
    return this.http.patch(environment.apiUrl + `end_user/users/v1/user_data/billings/${id}/`, data);
  }

  editMe(data) {
    return this.http.post(environment.apiUrl + 'end_user/users/v1/user_data/edit_me/', data);
  }

  setMeAndBillingData(data) {
    return this.http.post(environment.apiUrl + 'end_user/users/v1/user_data/set_me_and_billing_data/', data);
  }

  getInternalAuthToken() {
    return this.http.get(environment.apiUrl + 'end_user/users/v1/user_actions/get_internal_auth_token/', {});
  }

  getUserNotifications(params) {
    return this.http.get(environment.apiUrl + 'end_user/users/v1/user_notifications/messages', { params });
  }

  markUserNotificationAsRead(id) {
    return this.http.get(environment.apiUrl + `end_user/users/v1/user_notifications/${id}/message`);
  }

  markAllNotificationsAsRead(user_notifications) {
    return this.http.post(environment.apiUrl + 'end_user/users/v1/user_notifications/mark_notifications_as_read/', { user_notifications })
  }
}
