import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path: 'account', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)},
  {path: 'iframe', loadChildren: () => import('./iframe/iframe.module').then(m => m.IframeModule)},
  {path: '', loadChildren: () => import('./panel/panel.module').then(m => m.PanelModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
