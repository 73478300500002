import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CartService } from "../../panel/shared/cart.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private cartService: CartService,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let promise = new Promise<boolean>((resolve, reject) => {
      const token = JSON.parse(localStorage.getItem('auth'));
      if (!token || !token.hasOwnProperty('api_key') || !token.api_key) {
        if (state.url.indexOf('tour-guide')) {
          this.router.navigate(['/iframe/wynwood/login'], {queryParams: {'tour': true}});
          return;
        }
        if (state.url.indexOf('iframe/wynwood')) {
          this.router.navigate(['/iframe/wynwood/login']);
          return;
        } else {
          this.router.navigate(['/account/login']);
        }
        reject();
      }

      if (this.authService.user.value) {
        resolve(true);
      } else {
        Promise.all([
          this.authService.me().toPromise(),
        ]).then(([me, cart]) => {
          this.authService.user.next(me);
          setTimeout(() => {
            resolve(true);
          })
        }).catch(error => {
          localStorage.removeItem('auth');
          this.authService.logout();
          reject(error);
        });
      }
    });
    return promise;
  }
}
