<footer>
  <div class="container footer-container">
    <img [src]="config?.branding_image" alt="Logo">
    <div class="links">
      <div>Terms & Conditions</div>
      <div>Privacy Policy</div>
      <div>Contact</div>
    </div>
  </div>
</footer>
