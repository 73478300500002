<header>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <a class="navbar-brand ml-3" routerLink="/">
        <img class="header-image" [src]="config?.branding_image"
             alt="Logo">
      </a>

      <div class="d-flex align-items-center">
        <div class="nav-item mr-4 d-flex d-lg-none">
          <div *ngIf="runCountdown && cart && cart?.selected_products_count" class="countdown pointer"
               routerLink="/cart" (click)="collapseMenu(); sendLog()">
            <countdown #cd [config]="countdownConfig"></countdown>
          </div>
          <a style="position: relative;" routerLink="/cart" (click)="collapseMenu(); sendLog()">
            <img class="cart-icon" [src]="'/assets/images/cart.svg'" alt="Cart icon">
            <div class="badge-icon"
                 *ngIf="cart && cart?.selected_products_count">{{cart?.selected_products_count <= 99 ? cart?.selected_products_count : +99}}</div>
          </a>
        </div>

        <button class="navbar-toggler  mr-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent" #navbarCollapse>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mr-4 d-none d-lg-flex">
            <div *ngIf="runCountdown && cart && cart?.selected_products_count" class="countdown pointer"
                 routerLink="/cart" (click)="collapseMenu(); sendLog()">
              <countdown #cd [config]="countdownConfig"
                         (event)="eventCountdown($event)"></countdown>
            </div>
            <a style="position: relative;" routerLink="/cart" (click)="collapseMenu(); sendLog()">
              <img class="cart-icon" [src]="'/assets/images/cart.svg'" alt="Cart icon">
              <div class="badge-icon"
                   *ngIf="cart && cart?.selected_products_count">{{cart?.selected_products_count <= 99 ? cart?.selected_products_count : +99}}</div>
            </a>
          </li>
          <li class="nav-item mr-lg-4 mt-3 mb-1 mt-lg-0 mb-lg-0" *ngIf="tokenChecked && user">
            <div>{{user?.end_user?.first_name}} {{user?.end_user?.last_name}}</div>
          </li>
          <li class="nav-item" *ngIf="tokenChecked && !user">
            <a class="nav-link primary-font-color" routerLink="/account/login" (click)="collapseMenu()">LOGIN</a>
          </li>
          <li class="nav-item">
            <a class="nav-link primary-font-color" routerLink="/events" (click)="collapseMenu()">Events</a>
          </li>
          <li class="nav-item" *ngIf="tokenChecked && user">
            <a class="nav-link primary-font-color" routerLink="/orders" (click)="collapseMenu()">Orders</a>
          </li>
          <li class="nav-item" *ngIf="tokenChecked && user">
            <a class="nav-link primary-font-color pointer" (click)="onLogout(); collapseMenu();">Logout</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
