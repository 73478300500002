import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './authentication/shared/token.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from "./panel/widgets/loader/loader.component";
import { FooterComponent } from "./panel/widgets/footer/footer.component";
import { NavbarComponent } from "./panel/widgets/navbar/navbar.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from './authentication/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IMaskModule } from "angular-imask";
import { CountdownModule } from "ngx-countdown";
import { NgxStripeModule } from 'ngx-stripe';
import { FacebookModule } from "ngx-facebook";
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule, ScreenTrackingService, DEBUG_MODE } from "@angular/fire/analytics";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { GlobalErrorHandler } from "./panel/shared/global-error-handler";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
localStorage.getItem('translate') === 'es' ? registerLocaleData(localeEs) : '';
// import * as firebase from 'firebase/app';

// firebase.initializeApp(environment.firebase);
export function momentAdapterFactory() {
  return adapterFactory(moment);
}

/* If needed change start day of week in calendar */
// moment.updateLocale('en', {
//   week: {
//     dow: 1, // set start of week to monday instead
//     doy: 0,
//   },
// });

if (window.location.origin.indexOf('localhost') === -1) {
  Sentry.init({
    dsn: "https://28bb6b5d945f4ba3924ad960fb483e8d@o494649.ingest.sentry.io/5599239",
    environment: environment.production ? 'prod' : 'develop',
    integrations: [
      new Integrations.BrowserTracing({
        // tracingOrigins: ["localhost", "https://yourserver.io/api"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    FooterComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    NgxStripeModule.forRoot(),
    ToastrModule.forRoot(),
    SharedModule,
    NgbModule,
    IMaskModule,
    CountdownModule,
    FacebookModule.forRoot(),
    // AgmCoreModule.forRoot({
    //   apiKey: environment.firebase.apiKey,
    // }),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    // AngularFirestoreModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: localStorage?.getItem('language') || 'en-US'
    },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: DEBUG_MODE, useValue: environment.showFirebaseLogs
    },
    ScreenTrackingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
